import React from 'react'
import { Row, Col, Button, Spin, Alert } from 'antd'
import _ from 'lodash'
import { net, Authorize } from 'ca-admin-core'
import ReactJson from 'react-json-view'

import {
  vehicleLock,
  vehicleUnlock,
  vehicleAuth,
  vehicleDeauth,
  vehicleLights,
  vehicleTrunk,
  vehicleStatus,
  vehiclePosition,
  vehicleForceEngineOff,
  vehicleModemReboot,
  bikeUnlock,
  bikeBuzz,
  bikeEnableGPS,
  bikeImmediateLocation,
  bikeReset,
  sms,
} from '../../api'

const { api } = net

const fatal = {
  fatal: 'Unknown error occured.',
}

export default class VehicleControls extends React.Component {
  state = {
    buttonWakeUpDisabled: false,
    controlResponse: {},
    executing: false,
    commandsCount: 0,
  }

  interval = null

  componentDidMount() {
    this.interval = setInterval(() => {
      this.setState(old => ({ commandsCount: old.commandsCount > 0 ? old.commandsCount - 1 : 0 }))
    }, 30 * 1000)
  }

  componentWillUnmount() {
    if (this.interval) {
      clearInterval(this.interval)
      this.interval = null
    }
  }

  handleVehicleAction = action => {
    const { modem: { imei } = {} } = this.props

    if (!imei) {
      return
    }

    this.setState(old => ({
      executing: true,
      controlResponse: {},
      commandsCount: old.commandsCount + 1,
    }))

    api(action({ imei }))
      .then(response => response.json())
      .then(json => {
        this.setState({
          executing: false,
          controlResponse: json,
        })
      })
      .catch(() => {
        this.setState({
          executing: false,
          controlResponse: fatal,
        })
      })
  }

  handleWakeUpAction = () => {
    const { modem: { imei, phone: msisdn } = {} } = this.props

    if (!imei || !msisdn) {
      return
    }

    // disable button in order to prevent accidental presses (multiple
    this.setState({ buttonWakeUpDisabled: true })

    const message = 'AT$PMGR=0,0,300,0,0'

    api(sms({ msisdn, message }))
      .then(response => response.json())
      .then(json => {
        this.setState({
          executing: false,
          controlResponse: json,
        })
      })
      .catch(() => {
        this.setState({
          executing: false,
          controlResponse: fatal,
        })
      })
  }

  render() {
    const { modem } = this.props
    const { imei, phone: msisdn, type } = modem || {}
    const { buttonWakeUpDisabled, controlResponse, executing, commandsCount } = this.state
    const controlsDisabled = _.isNil(imei) || executing || commandsCount > 3

    const controlsCar = (
      <>
        <Row gutter={10}>
          <Col span={12}>
            <Button
              icon="lock"
              disabled={controlsDisabled}
              block
              onClick={() => this.handleVehicleAction(vehicleLock)}
            >
              Lock
            </Button>
          </Col>
          <Col span={12}>
            <Button
              icon="unlock"
              disabled={controlsDisabled}
              block
              onClick={() => this.handleVehicleAction(vehicleUnlock)}
            >
              Unlock
            </Button>
          </Col>
        </Row>
        <Row gutter={10} style={{ marginTop: 10 }}>
          <Col span={12}>
            <Button
              icon="check-square"
              disabled={controlsDisabled}
              block
              onClick={() => this.handleVehicleAction(vehicleAuth)}
            >
              Authorize
            </Button>
          </Col>
          <Col span={12}>
            <Button
              icon="close-square"
              disabled={controlsDisabled}
              block
              onClick={() => this.handleVehicleAction(vehicleDeauth)}
            >
              Deauthorize
            </Button>
          </Col>
        </Row>
        <Row gutter={10} style={{ marginTop: 10 }}>
          <Col span={12}>
            <Button
              icon="thunderbolt"
              disabled={controlsDisabled}
              block
              onClick={() => this.handleVehicleAction(vehicleLights)}
            >
              Lights
            </Button>
          </Col>
          <Col span={12}>
            <Button
              icon="tool"
              disabled={controlsDisabled}
              block
              onClick={() => this.handleVehicleAction(vehicleTrunk)}
            >
              Trunk
            </Button>
          </Col>
        </Row>
        <Row gutter={10} style={{ marginTop: 10 }}>
          <Col span={12}>
            <Button
              icon="info-circle"
              disabled={controlsDisabled}
              block
              type="dashed"
              onClick={() => this.handleVehicleAction(vehicleStatus)}
            >
              Status
            </Button>
          </Col>
          <Col span={12}>
            <Button
              icon="environment"
              disabled={controlsDisabled}
              block
              type="dashed"
              onClick={() => this.handleVehicleAction(vehiclePosition)}
            >
              Position
            </Button>
          </Col>
        </Row>
        <Row gutter={10} style={{ marginTop: 10 }}>
          <Col span={8}>
            <Button
              icon="coffee"
              disabled={controlsDisabled || buttonWakeUpDisabled || !msisdn}
              block
              type="dashed"
              onClick={() => this.handleWakeUpAction()}
            >
              Wake up
            </Button>
          </Col>
          <Col span={8}>
            <Button
              icon="redo"
              disabled={controlsDisabled}
              block
              type="dashed"
              onClick={() => this.handleVehicleAction(vehicleModemReboot)}
            >
              Reboot
            </Button>
          </Col>
          <Col span={8}>
            <Button
              icon="stop"
              disabled={controlsDisabled}
              block
              type="danger"
              onClick={() => this.handleVehicleAction(vehicleForceEngineOff)}
            >
              Force engine off
            </Button>
          </Col>
        </Row>
      </>
    )

    const controlsBike = (
      <>
        <Row gutter={10}>
          <Col span={12}>
            <Button
              icon="unlock"
              disabled={controlsDisabled}
              block
              onClick={() => this.handleVehicleAction(bikeUnlock)}
            >
              Unlock
            </Button>
          </Col>
          <Col span={12}>
            <Button
              icon="sound"
              disabled={controlsDisabled}
              block
              onClick={() => this.handleVehicleAction(bikeBuzz)}
            >
              Buzz
            </Button>
          </Col>
        </Row>
        <Row gutter={10} style={{ marginTop: 10 }}>
          <Col span={12}>
            <Button
              icon="compass"
              disabled={controlsDisabled || type === 3}
              block
              type="dashed"
              onClick={() => this.handleVehicleAction(bikeEnableGPS)}
            >
              Enable GPS
            </Button>
          </Col>
          <Col span={12}>
            <Button
              icon="environment"
              disabled={controlsDisabled}
              block
              type="dashed"
              onClick={() => this.handleVehicleAction(bikeImmediateLocation)}
            >
              Get location
            </Button>
          </Col>
        </Row>
        <Row gutter={10} style={{ marginTop: 10 }}>
          <Col span={12}>
            <Button
              icon="reload"
              disabled={controlsDisabled || type === 3}
              block
              onClick={() => this.handleVehicleAction(bikeReset)}
            >
              Reset
            </Button>
          </Col>
        </Row>
      </>
    )

    if (_.isUndefined(type)) {
      return <div>No modem assigned</div>
    }

    return (
      <div>
        <p>IMEI: {imei}</p>

        {commandsCount > 1 && (
          <Alert
            className="mb-3"
            message="Do not overuse commands"
            description="1-2 commands every 30 seconds is ok..."
            type="warning"
            showIcon
          />
        )}

        {type === 1 && <Authorize roles={['admin', 'fleet-operator']}>{controlsCar}</Authorize>}

        {type !== 1 && (
          <Authorize roles={['admin', 'bike-operator', 'security-operator']}>
            {controlsBike}
          </Authorize>
        )}

        <div style={{ marginTop: 20 }}>Command response:</div>

        {!executing && <ReactJson collapsed={false} src={controlResponse} />}
        {executing && (
          <div className="m-4 text-center">
            <Spin />
          </div>
        )}
      </div>
    )
  }
}

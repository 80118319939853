import { getToken, net } from 'ca-admin-core'
import { API_URL, FLEET_API_URL } from 'utils/constants'

const { get, postJson, putJson } = net

const API_MODEMS = `${FLEET_API_URL}/modems`
const API_SMS = `${API_URL}/sms`
const API_CONTROLS = `${API_URL}/vehicles/command`

export const modems = params => get(API_MODEMS, params, getToken())
export const createModem = params => postJson(API_MODEMS, params, getToken())
export const modemDetails = id => get(`${API_MODEMS}/${id}`, null, getToken())
export const updateModem = (id, params) => putJson(`${API_MODEMS}/${id}`, params, getToken())

export const vehicleLock = params => postJson(`${API_CONTROLS}/lock`, params, getToken())
export const vehicleUnlock = params => postJson(`${API_CONTROLS}/unlock`, params, getToken())
export const vehicleAuth = params => postJson(`${API_CONTROLS}/auth`, params, getToken())
export const vehicleDeauth = params => postJson(`${API_CONTROLS}/deauth`, params, getToken())
export const vehicleLights = params => postJson(`${API_CONTROLS}/lights`, params, getToken())
export const vehicleTrunk = params => postJson(`${API_CONTROLS}/trunk`, params, getToken())
export const vehicleStatus = params => postJson(`${API_CONTROLS}/status`, params, getToken())
export const vehiclePosition = params => postJson(`${API_CONTROLS}/position`, params, getToken())
export const vehicleModemReboot = params => postJson(`${API_CONTROLS}/reboot`, params, getToken())
export const vehicleForceEngineOff = params =>
  postJson(`${API_CONTROLS}/forceoff`, params, getToken())

export const bikeUnlock = params => postJson(`${API_CONTROLS}/unlock`, params, getToken())
export const bikeBuzz = params => postJson(`${API_CONTROLS}/sound`, params, getToken())
export const bikeEnableGPS = params => postJson(`${API_CONTROLS}/enablegps`, params, getToken())
export const bikeImmediateLocation = params =>
  postJson(`${API_CONTROLS}/immediatelocation`, params, getToken())
export const bikeReset = params => postJson(`${API_CONTROLS}/reset`, params, getToken())

export const sms = params => postJson(API_SMS, params, getToken())

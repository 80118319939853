import { Loadable } from 'ca-admin-core'

import trips from './redux/list/reducers'
import tripDetails from './redux/details/reducers'
import tripEnd from './redux/end/reducers'
import tripStart from './redux/start/reducers'
import tripRetryPayment from './redux/retryPayment/reducers'
import tripClearWarning from './redux/clearWarning/reducers'

import tripsSaga from './redux/list/sagas'
import tripDetailsSaga from './redux/details/sagas'
import tripEndSaga from './redux/end/sagas'
import tripStartSaga from './redux/start/sagas'
import tripRetryPaymentSaga from './redux/retryPayment/sagas'
import tripClearWarningSaga from './redux/clearWarning/sagas'

export { startTrip } from './api'
export { default as List } from './components/List'
export { default as TripStatusTag } from './components/tags/Status'
export { default as TripTypeTag } from './components/tags/Type'

export { webUrl } from './constants'

export default {
  name: 'trips',
  menu: [
    {
      title: 'Trips',
      key: 'trips',
      icon: 'icmn icmn-compass',
      roles: ['admin', 'operator', 'fleet-owner'],
      children: [
        {
          title: 'All',
          key: 'trips',
          url: '/trips',
        },
        {
          title: 'Ongoing',
          key: 'trips-ongoing',
          url: '/trips/filter/ongoing',
        },
      ],
    },
  ],
  routes: [
    {
      path: ['/trips/filter/:filter?', '/trips'],
      component: Loadable(() => import('modules/trips/pages')),
      exact: true,
    },
    {
      path: '/trips/:id',
      component: Loadable(() => import('modules/trips/pages/details')),
      exact: true,
      modal: true,
    },
  ],
  reducers: {
    trips,
    tripDetails,
    tripEnd,
    tripStart,
    tripRetryPayment,
    tripClearWarning,
  },
  sagas: [
    tripsSaga(),
    tripDetailsSaga(),
    tripEndSaga(),
    tripStartSaga(),
    tripRetryPaymentSaga(),
    tripClearWarningSaga(),
  ],
}

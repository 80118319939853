import { extraNetworkActions } from 'ca-admin-core'

const actions = {
  ...extraNetworkActions('CLEAR_WARNING', 'trip/clearWarning'),
}

export default actions

export function clearWarning(uuid) {
  return {
    type: actions.CLEAR_WARNING,
    payload: {
      uuid,
    },
  }
}

import { all, takeLatest, takeEvery, put, select } from 'redux-saga/effects'
import { notification } from 'antd'
import { request } from 'ca-admin-core'
import API from 'modules/trips/api'
import actions from './actions'

const { clearWarning } = API

function* CLEAR_WARNING({ payload: { uuid } }) {
  yield put(request(clearWarning(uuid), actions.CLEAR_WARNING))
}

function* SUCCESS() {
  const lastCharge = yield select(state => state.tripDetails.trip.lastCharge)

  if (!lastCharge) {
    yield notification.error({
      message: 'Failed!',
      description: 'Clear warning failed!',
    })
    return
  }

  yield notification.success({
    message: 'Done!',
    description: 'Warning clear successful!',
  })
}

export default function* rootSaga() {
  yield all([
    takeLatest(actions.CLEAR_WARNING, CLEAR_WARNING),
    takeEvery(actions.CLEAR_WARNING_SUCCESS, SUCCESS),
  ])
}
